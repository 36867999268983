<template>
  <li v-if="canRender" v-c-modal:imageDrawer>
    <camera-wall-image-drawer :item="camera" ref="imageDrawer" />
    <img :src="camera.image_url" width="16" height="9" alt="">
    <time> {{ camera ? (camera.taken ? camera.taken : '')  + ' - ' + (camera.screen_name ? camera.screen_name : '') : '' }} </time>
    <button type="button" class="btn btn-remove">
      <i class="far fa-times"></i>
    </button>
  </li>
  <li v-else class="empty">
    <time> {{ camera ? (camera.taken ? camera.taken : '')  + ' - ' + (camera.screen_name ? camera.screen_name : '') : '' }} </time>
    <button @click="openDrawer" class="btn btn-no-screen">{{ camera ? 'No Image Available' : 'No Screen Set' }}</button>
  </li>
</template>

<script>
import CameraWallImageDrawer from './CameraWallImageDrawer'

export default {
  components: {
    CameraWallImageDrawer
  },

  props: {
    camera: {
      type: Object,
      default: null,
      required: false
    }
  },

  computed: {
    canRender () {
      return this.camera && this.camera.image_url
    }
  },

  methods: {
    openDrawer () {
      if (!this.camera) {
        this.$emit('viewSettings')
      }
    }
  }
}
</script>
